import React, { useState } from 'react';
import { getEnergyForecastFromChatGPT, getEnergyAnalysisFromChatGPT } from '../../utils/apis/customer';
import Chart from 'react-apexcharts';
import toast from 'react-hot-toast';
import { apiPostGetSolarPanelData } from '../../utils/apis/mqtt';
import { useData } from '../../context/Context';

export default function Fault() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [forecast, setForecast] = useState([]);
    const [actualProduction, setActualProduction] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [analysis, setAnalysis] = useState('');
    const [showResults, setShowResults] = useState(false);

    const today = new Date().toISOString().split('T')[0];

    const { user } = useData();
    const token = user ? user.token : '';

    const handleStartDateChange = (e) => {
        const selectedDate = e.target.value;
        setStartDate(selectedDate);
    };

    const handleEndDateChange = (e) => {
        const selectedDate = e.target.value;
        setEndDate(selectedDate);
    };

    
    const groupDataByDate = (data) => {
        return data.reduce((acc, item) => {
            const date = new Date(item.createDate).toISOString().split('T')[0];
            if (!acc[date]) {
                acc[date] = 0;
            }
            acc[date] += parseFloat(item.power);
            return acc;
        }, {});
    };

 
    const formatDailyPowerData = (dailyData) => {
        return Object.keys(dailyData).map((date) => ({
            date: date,
            energy: dailyData[date].toFixed(2),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setForecast([]);
        setActualProduction([]);
        setAnalysis('');
        setShowResults(false);

        if (!startDate || !endDate) {
            toast.error('Lütfen hem başlangıç hem de bitiş tarihlerini seçin!');
            return;
        }

        setLoading(true);

        try {
            const startDateISO = startDate;
            const endDateISO = endDate;

            const historicalStartDate = '2024-09-20'; 
            const historicalEndDate = today; 

        
            const energyDataResponse = await apiPostGetSolarPanelData(token, historicalStartDate, historicalEndDate);
            console.log('energyDataResponse:', energyDataResponse);

            if (Array.isArray(energyDataResponse) && energyDataResponse.length > 0) {
                
                const powerData = energyDataResponse.map(item => ({
                    power: item.power,
                    createDate: item.createDate
                }));

               
                const dailyPowerData = groupDataByDate(powerData);
                const formattedPowerData = formatDailyPowerData(dailyPowerData);

                console.log('ChatGPT\'ye gönderilen günlük enerji verileri (W cinsinden):', formattedPowerData);

                const numberOfDays = Math.ceil(
                    (new Date(endDateISO) - new Date(startDateISO)) /
                    (1000 * 60 * 60 * 24)
                ) + 1;

                
                const chatGptResponse = await getEnergyForecastFromChatGPT(formattedPowerData, numberOfDays, startDateISO, endDateISO);
                setForecast(chatGptResponse);

                const actualProductionDataResponse = await apiPostGetSolarPanelData(token, startDateISO, endDateISO);
                console.log('Gerçek Enerji Üretim Verileri:', actualProductionDataResponse);

               
                const actualProductionData = actualProductionDataResponse.map((item) => ({
                    date: item.createDate.split('T')[0],
                    energy: parseFloat(item.power)
                }));

               
                const aggregatedActualProduction = actualProductionData.reduce((acc, curr) => {
                    const date = curr.date;
                    if (!acc[date]) {
                        acc[date] = { date: date, energy: 0 };
                    }
                    acc[date].energy += curr.energy;
                    return acc;
                }, {});

            
                const actualProductionArray = Object.values(aggregatedActualProduction).sort((a, b) => new Date(a.date) - new Date(b.date));

                setActualProduction(actualProductionArray);

             
                const comparisonData = actualProductionArray.map(actual => {
                    const forecasted = forecast.find(f => f.date === actual.date);
                    return {
                        date: actual.date,
                        actual: actual.energy,
                        forecast: forecasted ? parseFloat(forecasted.energy) : 0,
                        difference: forecasted ? (actual.energy - parseFloat(forecasted.energy)).toFixed(2) : 0
                    };
                });

                console.log('Karşılaştırma Verisi:', comparisonData);

                
                const analysisResponse = await getEnergyAnalysisFromChatGPT(comparisonData, startDateISO, endDateISO);
                setAnalysis(analysisResponse);

                setShowResults(true);
            } else {
                console.error('Enerji verileri alınamadı veya boş.');
                setError('Enerji verileri alınamadı veya boş.');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const chartOptions = {
        chart: {
            type: 'bar',
        },
        xaxis: {
            categories: forecast.map(item => formatDate(item.date)),
            labels: {
                formatter: (value) => value
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '80%',
            },
        },
        dataLabels: {
            enabled: false,
        },
        title: {
            text: 'Tahmin Edilen Enerji Üretimi',
        },
        series: [{
            name: 'Tahmin Edilen Enerji Üretimi (W)',
            data: forecast.map(item => parseFloat(item.energy))
        }]
    };

    const actualChartOptions = {
        chart: {
            type: 'bar',
        },
        xaxis: {
            categories: actualProduction.map(item => formatDate(item.date)),
            labels: {
                formatter: (value) => value
            }
        },
        colors: ["#FFC300"], 
        plotOptions: {
            bar: {
                columnWidth: '80%',
            },
        },
        dataLabels: {
            enabled: false,
        },
        title: {
            text: 'Gerçek Enerji Üretimi',
        },
        series: [{
            name: 'Gerçek Enerji Üretimi (W)',
            data: actualProduction.map(item => parseFloat(item.energy))
        }]
    };

    const totalEnergy = forecast.reduce((acc, curr) => acc + parseFloat(curr.energy), 0).toFixed(2);
    const averageEnergy = (forecast.length ? (totalEnergy / forecast.length).toFixed(2) : 0);

 
    const ComparisonTable = ({ data }) => (
        <div className="mt-4">
            <h4>Gerçek vs Tahmin Edilen Enerji Üretimi</h4>
            <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                    <tr>
                        <th>Tarih</th>
                        <th>Gerçek Üretim (W)</th>
                        <th>Tahmin Edilen Üretim (W)</th>
                        <th>Fark (W)</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index} className={row.difference < 0 ? 'table-danger' : 'table-success'}>
                            <td>{formatDate(row.date)}</td>
                            <td>{row.actual}</td>
                            <td>{row.forecast}</td>
                            <td>{row.difference}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="container mt-5">
            <h2><i className='ti ti-bolt'></i> Üretim Analizi</h2>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="startDate" className="form-label">Başlangıç Tarihi</label>
                        <input type="date" className="form-control" id="startDate" onChange={handleStartDateChange} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="endDate" className="form-label">Bitiş Tarihi</label>
                        <input type="date" className="form-control" id="endDate" onChange={handleEndDateChange} min={startDate || ''} />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">Analiz Yap</button>
            </form>
            {loading && (
                <div className="mt-4 text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Yükleniyor...</span>
                    </div>
                </div>
            )}
            {showResults && (
                <div className="mt-4">
                   {analysis && (
                        <div className="card analysis-card shadow-sm p-4 mt-4">
                            <h4 className="card-title">Analiz:</h4>
                            <p className="card-text">{analysis}</p>
                        </div>
                    )}
                  <ComparisonTable data={actualProduction.map(actual => {
                        const forecasted = forecast.find(f => f.date === actual.date);
                        return {
                            date: actual.date,
                            actual: actual.energy,
                            forecast: forecasted ? parseFloat(forecasted.energy) : 0,
                            difference: forecasted ? (actual.energy - parseFloat(forecasted.energy)).toFixed(2) : 0
                        };
                    })} />
                   
                    <h4>Tahmin Edilen Enerji Üretimi:</h4>
                    <Chart
                        options={chartOptions}
                        series={chartOptions.series}
                        type="bar"
                        height="350"
                    />
                    <h4 className="mt-4">Gerçek Enerji Üretimi:</h4>
                    <Chart
                        options={actualChartOptions}
                        series={actualChartOptions.series}
                        type="bar"
                        height="350"
                    />
                    
                </div>
            )}
            {error && (
                <div className="mt-4 alert alert-danger">
                    <h4>Hata:</h4>
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
}

import React, { useState } from 'react';
import { getEnergyForecastFromChatGPT } from '../../utils/apis/customer';
import Chart from 'react-apexcharts';
import toast from 'react-hot-toast';
import { apiPostGetSolarPanelData } from '../../utils/apis/mqtt';
import { useData } from '../../context/Context';

export default function Forecast() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [forecast, setForecast] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const today = new Date().toISOString().split('T')[0];

    const { user } = useData();
    const token = user ? user.token : '';

    const handleStartDateChange = (e) => {
        const selectedDate = e.target.value;
        setStartDate(selectedDate);
    };

    const handleEndDateChange = (e) => {
        const selectedDate = e.target.value;
        setEndDate(selectedDate);
    };

    
    const groupDataByDate = (data) => {
        return data.reduce((acc, item) => {
            const date = new Date(item.createDate).toISOString().split('T')[0];
            if (!acc[date]) {
                acc[date] = 0;
            }
            acc[date] += parseFloat(item.power);
            return acc;
        }, {});
    };

    
    const formatDailyPowerData = (dailyData) => {
        return Object.keys(dailyData).map((date) => ({
            date: date,
            energy: dailyData[date].toFixed(2),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setForecast([]);

        if (!startDate || !endDate) {
            toast.error('Lütfen hem başlangıç hem de bitiş tarihlerini seçin!');
            return;
        }

        setLoading(true);

        try {
            const startDateISO = startDate;
            const endDateISO = endDate;

            const historicalStartDate = '2024-09-20';
            const historicalEndDate = today;

            const energyDataResponse = await apiPostGetSolarPanelData(token, historicalStartDate, historicalEndDate);
            console.log('energyDataResponse:', energyDataResponse);

            if (Array.isArray(energyDataResponse) && energyDataResponse.length > 0) {
                const powerData = energyDataResponse.map(item => ({
                    power: item.power,
                    createDate: item.createDate
                }));

     
                const dailyPowerData = groupDataByDate(powerData);
                const formattedPowerData = formatDailyPowerData(dailyPowerData);

                console.log('ChatGPT\'ye gönderilen günlük enerji verileri (W cinsinden):', formattedPowerData);

                const numberOfDays = Math.ceil(
                    (new Date(endDateISO) - new Date(startDateISO)) /
                    (1000 * 60 * 60 * 24)
                ) + 1;

                const chatGptResponse = await getEnergyForecastFromChatGPT(formattedPowerData, numberOfDays, startDateISO, endDateISO);
                setForecast(chatGptResponse);
            } else {
                console.error('Enerji verileri alınamadı veya boş.');
                setError('Enerji verileri alınamadı veya boş.');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const chartOptions = {
        chart: {
            type: 'bar',
        },
        xaxis: {
            categories: forecast.map(item => formatDate(item.date)),
            labels: {
                formatter: (value) => value
            }
        },
        plotOptions: {
            bar: {
                colors: {
                    ranges: [
                        {
                            from: 0,
                            to: 10000,
                            color: '#13deb9'
                        },
                        {
                            from: 10000,
                            to: 20000,
                            color: '#ffc107'
                        },
                        {
                            from: 20000,
                            to: 100000,
                            color: '#dc3545'
                        }
                    ]
                },
                columnWidth: '80%',
            },
        },
        dataLabels: {
            enabled: false,
        },
        title: {
            text: 'Enerji Üretim Tahmini',
        },
        series: [{
            name: 'Enerji Üretimi (W)',
            data: forecast.map(item => parseFloat(item.energy))
        }]
    };

    const totalEnergy = forecast.reduce((acc, curr) => acc + parseFloat(curr.energy), 0).toFixed(2);
    const averageEnergy = (forecast.length ? (totalEnergy / forecast.length).toFixed(2) : 0);

    return (
        <div className="container mt-5">
            <h2><i className='ti ti-bolt'></i> Enerji Üretim Tahmini</h2>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="startDate" className="form-label">Başlangıç Tarihi</label>
                        <input type="date" className="form-control" id="startDate" onChange={handleStartDateChange} min={today} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="endDate" className="form-label">Bitiş Tarihi</label>
                        <input type="date" className="form-control" id="endDate" onChange={handleEndDateChange} min={startDate || today} />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">Tahmin Yap</button>
                <div className="mt-4">
                    <h4>Enerji Tahmin Seviyeleri</h4>
                    <div className="d-flex justify-content-around">
                        <div className="text-center">
                            <div className="text-white rounded-circle" style={{ width: '30px', height: '30px', display: 'inline-block', backgroundColor: '#13deb9' }}></div>
                            <p>0-10.000 W <br /> (Düşük)</p>
                        </div>
                        <div className="text-center">
                            <div className="text-dark rounded-circle" style={{ width: '30px', height: '30px', display: 'inline-block', backgroundColor: '#ffc107' }}></div>
                            <p>10.000-20.000 W <br /> (Orta)</p>
                        </div>
                        <div className="text-center">
                            <div className="text-white rounded-circle" style={{ width: '30px', height: '30px', display: 'inline-block', backgroundColor: '#dc3545' }}></div>
                            <p>20.000+ W <br /> (Yüksek)</p>
                        </div>
                    </div>
                </div>
            </form>
            {loading && (
                <div className="mt-4 text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Yükleniyor...</span>
                    </div>
                </div>
            )}
            {forecast.length > 0 && (
                <div className="mt-4">
                    <h4>Tahmin Sonuçları:</h4>
                    <Chart
                        options={chartOptions}
                        series={chartOptions.series}
                        type="bar"
                        height="350"
                    />
                    <div className="row mt-4">
                        <div className="col-md-6 mb-3">
                            <div className="card shadow-sm border-0">
                                <div className="card-body text-center">
                                    <h5 className="card-title font-weight-bold text-uppercase">Toplam Enerji Üretimi</h5>
                                    <p className="card-text display-5 text-success uretim">{totalEnergy} W</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card shadow-sm border-0">
                                <div className="card-body text-center">
                                    <h5 className="card-title font-weight-bold text-uppercase">Ortalama Enerji Üretimi</h5>
                                    <p className="card-text display-5 text-warning uretim">{averageEnergy} W</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {error && (
                <div className="mt-4 alert alert-danger">
                    <h4>Hata:</h4>
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
}

import { useNavigate } from "react-router-dom";
import { useData } from "../context/Context";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

export default function Header({ links, children }) {
  const { user, logout } = useData();
  const navigate = useNavigate();
  const ref = useRef();
  const [show, setShow] = useState(false);

  const handleShow = () => {
    if (window.innerWidth < 1200) {
      if (show) {
        ref.current.classList.remove("show-sidebar");
        ref.current.classList.add("mini-sidebar");
        setShow(false);
      } else {
        ref.current.classList.add('show-sidebar');
        ref.current.classList.remove("mini-sidebar");
        setShow(true);
      }
    }
  };

  useEffect(() => {
    if (!user) {
      navigate('/ges', { replace: true });
    }
  }, [user, navigate]);

  const handleLogout = () => {
    if (user) {
      logout();
      toast.success("Çıkış Yapıldı");
      navigate('/ges', { replace: true });
    }
  };

  const getRoleName = (roleId) => {
    switch (roleId) {
      case 1:
        return 'Admin';
      case 2:
        return 'Müdür';
      case 3:
        return 'Personel';
      default:
        return '';
    }
  };

  return (
    <div ref={ref} className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
      <aside className="left-sidebar">
        <div>
          <div className="brand-logo d-flex align-items-center justify-content-between">
            <a href="/" className="text-nowrap logo-img">
              <img src="themes/solartech/assets/images/logos/logo.png" width={180} alt="logo" />
            </a>
            <div onClick={handleShow} className="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
              <i className="ti ti-x fs-8" />
            </div>
          </div>
          <nav className="sidebar-nav scroll-sidebar" data-simplebar>
            <ul id="sidebarnav">
              <li className="nav-small-cap">
                <i className="ti ti-dots nav-small-cap-icon fs-4" />
              </li>
              {links.map(link => (
                <li key={link.id} className="sidebar-item">
                  <a className="sidebar-link" href={link.link} aria-expanded="false">
                    <span>
                      <i className={link.icon} />
                    </span>
                    <span className="hide-menu">{link.label}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </aside>
      <div className="body-wrapper">
        <header className="app-header">
          <nav className="navbar navbar-expand navbar-light">
            <ul className="navbar-nav d-block d-xl-none">
              <li className="nav-item">
                <a onClick={handleShow} className="nav-link nav-icon-hover" id="headerCollapse">
                  <i className="ti ti-menu-2" />
                </a>
              </li>
            </ul>
            <div className="navbar-collapse justify-content-end px-0 w-100" id="navbarNav">
              <ul className="navbar-nav ms-auto align-items-center">
                <li className="nav-item dropdown">
                  <a className="nav-link nav-icon-hover" href="#" id="drop2" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="themes/solartech/assets/images/profile/user-1.jpg" alt="profile" width={50} height={50} className="rounded-circle header-avatar" />
                    <i className="ti ti-menu-2 ms-2 " />
                  </a>
                  <div className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                    <div className="message-body">
                      <div className="d-flex flex-column align-items-center">
                        <span className="user-name">{user?.name} {user?.surname}</span>
                        <div className="role-name-container">
                          <span className="role-name-line"></span>
                          <span className="role-name">{getRoleName(user?.roleId)}</span>
                          <span className="role-name-line"></span>
                        </div>
                      </div>
                      <hr style={{ width: '100%', margin: '0.5rem 0' }} />
                      <a href="/profile" className="d-flex align-items-center gap-2 dropdown-item">
                        <i className="ti ti-user fs-6" />
                        <p className="mb-0 fs-3">Profilim</p>
                      </a>
                      <button className="d-flex align-items-center gap-2 dropdown-item" onClick={handleLogout}>
                        <i className="ti ti-logout fs-6" style={{ color: 'red' }} />
                        <p className="mb-0 fs-3" style={{ color: 'red' }}>Çıkış Yap</p>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <div className="container-fluid">
          {children}
        </div>
      </div>
    </div>
  );
}

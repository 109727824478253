import { instance } from "./base";
import axios from "axios";

export const apiPostLogin = async (email, password) => {
    try {
        const response = await instance.post('/api/Authorization/Login', {
            email,
            password,
            isRemember: true
        });
        return response.data;
    } catch (error) {
        throw new Error(`Giriş başarısız: ${error.message}`);
    }
};
export const apiPostGoogleSignIn = async ({ email, name, surname, pictureUrl, phone }) => {
    try {
        const response = await instance.post('/api/Authorization/GoogleSignIn', {
            email,
            name,
            surname,
            pictureUrl,
            phone
        });
        return response.data;
    } catch (error) {
        throw new Error(`Google ile giriş başarısız: ${error.message}`);
    }
};

export const apiPutUserInfo = async (token, user) => {
    try {
        const response = await instance.put('/api/Authorization/UpdateUserInfo', {
            name: user.name,
            surname: user.surname,
            phone: user.phone,
            email: user.email
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Kullanıcı bilgileri güncellenemedi: ${error.message}`);
    }
};

export const apiPostChangePassword = async (token, oldPassword, newPassword, confirmPassword) => {
    try {
        const response = await instance.post('/api/Authorization/ChangePassword', {
            oldPassword,
            newPassword,
            confirmPassword
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Şifre değiştirme başarısız: ${error.message}`);
    }
};

export const apiPostUserRegister = async (name, surname, phone, email, deviceNumber) => {
    try {
        const response = await instance.post('/api/Authorization/Register', {
            name,
            surname,
            phone,
            email,
            deviceNumber
        });
        return response.data;
    } catch (error) {
        throw new Error(`Kullanıcı kaydı başarısız: ${error.message}`);
    }
};


export const fetchWeatherForecast = async () => {
    const API_KEY = '893266d84a86f8050d4da0a2286e7696';
    const latitude = 37.7968911;
    const longitude = 41.067373;
    const url = `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${API_KEY}`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw new Error('Hava durumu verileri alınamadı.');
    }
};
export const getEnergyForecastFromChatGPT = async (formattedPowerData, numberOfDays, startDateISO, endDateISO) => {
   
    const apiKey = "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";
    const apiUrl = "https://api.openai.com/v1/chat/completions";
    try {
        
        const userMessage = `Size geçmiş günlük enerji üretim verilerini sağlıyorum. Bu veriler "energy" değerlerini ve tarihleri içerir ve "W" cinsindendir. Verileri analiz ederken mevsimsel trendleri, haftalık ve aylık dalgalanmaları dikkate alın. Tahmininiz gerçekçi dalgalanmaları yansıtmalı ve sadece lineer bir artış veya azalış olmamalıdır. ${startDateISO} ile ${endDateISO} tarihleri arasındaki (${numberOfDays} gün) enerji üretim tahminini yapmanızı istiyorum. Çıktınızı aşağıdaki JSON formatında verin:

{"energy_production": [{"date": "YYYY-MM-DD", "energy": değer_W_cinsinden}]}

İşte geçmiş günlük enerji üretim verileri:

${JSON.stringify(formattedPowerData)}`;

        const response = await axios.post(apiUrl, {
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "Sen bir enerji üretim uzmanısın."
                },
                {
                    role: "user",
                    content: userMessage
                }
            ],
            temperature: 0.7,
            max_tokens: 1000,
            n: 1,
            stop: null,
        }, {
            headers: {
                "Authorization": `Bearer ${apiKey}`,
                "Content-Type": "application/json"
            }
        });

        const content = response.data.choices[0].message.content.trim();

        
        let parsedResponse;
        try {
            parsedResponse = JSON.parse(content.match(/{[\s\S]*}/)[0]);
        } catch (parseError) {
            console.error("Yanıt Ayrıştırma Hatası:", parseError);
            throw new Error('Yapay Zeka yanıtı beklenen formatta değil.');
        }

        return parsedResponse.energy_production;
    } catch (error) {
        console.error("Yapay Zeka İletişim Hatası:", error);
        throw new Error('Yapay Zeka ile iletişim kurulamadı.');
    }
};
export const getEnergyAnalysisFromChatGPT = async (comparisonData, startDate, endDate) => {
    const apiKey = "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";  
    const apiUrl = "https://api.openai.com/v1/chat/completions";

    try {
        const response = await axios.post(apiUrl, {
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "Sen bir enerji uzmanısın. Gerçek enerji üretimi ile tahmin edilen enerji üretimini analiz edip farkları yorumlayacak ve kullanıcılara verimliliği artırmaları için öneriler sunacaksın."
                },
                {
                    role: "user",
                    content: `Size tahmin edilen ve gerçek enerji üretimi verilerini sağlıyorum. Bu veriler ${startDate} ile ${endDate} tarihleri arasındaki üretimi temsil etmektedir. Veriler şu formatta sunulmuştur:

                    ${JSON.stringify(comparisonData)}

                    Lütfen verileri analiz edin, verimlilik farklarını belirtin ve nasıl daha fazla enerji verimliliği sağlanabileceği konusunda önerilerde bulunun.`
                }
            ],
            temperature: 0.7,
            max_tokens: 1000,
            n: 1,
            stop: null,
        }, {
            headers: {
                "Authorization": `Bearer ${apiKey}`,
                "Content-Type": "application/json"
            }
        });

        const content = response.data.choices[0].message.content.trim();
        return content;
    } catch (error) {
        console.error("ChatGPT ile analiz yapılamadı:", error);
        throw new Error('ChatGPT ile analiz yapılamadı.');
    }
};




export const analyzeProductionDifference = async (predicted, actual) => {
    const apiKey = "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";
    const apiUrl = "https://api.openai.com/v1/chat/completions";

    try {
        const response = await axios.post(apiUrl, {
            model: "gpt-4-turbo", 
            messages: [
                {
                    role: "system",
                    content: "Sen bir güneş paneli enerji üretim uzmanısın."
                },
                {
                    role: "user",
                    content: `Sadece istenilen bilgileri listele ve hiçbir giriş veya yorum ekleme. Güneş panellerinin verimliliğini artırmak için uygulanabilecek en iyi yöntemleri, sıralanmış bir liste halinde, numaralandırarak ver. Her öneriyi kısa ve öz cümlelerle belirt.`
                }
            ]
        }, {
            headers: {
                "Authorization": `Bearer ${apiKey}`,
                "Content-Type": "application/json"
            }
        });
        return response.data.choices[0].message.content;
    } catch (error) {
        throw new Error(`Yapay zeka analizi başarısız: ${error.message}`);
    }
};




export const apiPostForgotPassword = async (email) => {
    try {
        const response = await instance.post('/api/Authorization/ForgotPassword', { email });
        return response.data;
    } catch (error) {
        throw new Error(`Şifre sıfırlama başarısız: ${error.message}`);
    }
};

export const apiSendMessage = async (fullname, email, mesaj) => {
    try {
        const response = await instance.post('http://apisolartech.softsense.com.tr/api/Communication/SendMessage', {
            fullname,
            email,
            mesaj
        });
        return response.data;
    } catch (error) {
        throw new Error(`Mesaj gönderimi başarısız: ${error.message}`);
    }
};





export const apiGetCareTypeList = async (token) => {
    try {
        const response = await instance.get('/api/Customer/GetCareTypeList', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.careTypeModels;
    } catch (error) {
        throw new Error(`Bakım türleri getirilemedi: ${error.message}`);
    }
}
export const apiPostAddNewMaintenance = async (token, maintenance, roleId) => {
    const url = roleId === 2
        ? '/api/Manager/AddCareForManager'
        : '/api/Customer/AddCare';

    try {
        const response = await instance.post(url, {
            careTypeId: maintenance.careTypeId,
            careDate: maintenance.careDate,
            details: maintenance.details
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Yeni bakım ekleme başarısız: ${error.message}`);
    }
};

export const apiGetCareList = async (token, roleId) => {
    const url = roleId === 2
        ? '/api/Manager/GetCareListForManager'
        : '/api/Customer/GetCareList';

    try {
        const response = await instance.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.careList;
    } catch (error) {
        throw new Error(`Bakım listesi getirilemedi: ${error.message}`);
    }
};

export const apiDeleteCare = async (token, careId) => {
    try {
        const response = await instance.delete('/api/Customer/DeleteCare', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                careId: careId
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Bakım silme başarısız: ${error.message}`);
    }
};

export const apiPutUpdateCare = async (token, care) => {
    try {
        const response = await instance.put('/api/Customer/UpdateCare', {
            careId: care.careId,
            careTypeId: care.careTypeId,
            careDate: care.careDate,
            details: care.details
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Bakım güncelleme başarısız: ${error.message}`);
    }
};
